import { Button } from '@mui/material'
import { SUPER_ADMIN } from '~/constants/constants'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import AddAddressPopup from './AddAddressPopup'
import { useTranslation } from 'react-i18next'

const permissions = [SUPER_ADMIN, 'administrator.definitions.addresses.create']

const NewAddress = () => {
    const [openAddAddress, setOpenAddAddress] = useState(false)
    const { t } = useTranslation()
    const userPermissions = useSelector(selectUserPermissions)
    const isPermission = useMemo(() => {
        return permissions.some((item) => userPermissions.includes(item))
    }, [userPermissions])

    if (isPermission) {
        return (
            <>
                <Button
                    sx={{ textTransform: 'none', fontWeight: 'normal' }}
                    onClick={() => setOpenAddAddress(true)}
                >
                    {t('button.newAddress')}
                </Button>

                <AddAddressPopup
                    openAddAddress={openAddAddress}
                    setOpenAddAddress={setOpenAddAddress}
                />
            </>
        )
    }

    return null
}

export default NewAddress
