import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import UploadIcon from '@mui/icons-material/Upload'
import { MenuItem } from '@mui/material'
import ActionMenu from '~/components/ActionMenu'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import ToolBarButton from '~/components/ToolBarButton'
import UploadPopup from '~/components/UploadPopup'
import { SUPER_ADMIN } from '~/constants/constants'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import AddSerialPopup from './AddSerialPopup'
import {
    useExportSerialMutation,
    useExportSerialQueueMutation,
    useImportSerialQueueMutation,
} from './mutate'
import PropTypes from 'prop-types'

const permission_addSerial = [SUPER_ADMIN, 'fleet.serials.addSerial']

const SerialListToolbar = ({ payload }) => {
    const [openPopup, setOpenPopup] = useState(false)
    const [openUpload, setOpenUpload] = useState(false)

    const { t } = useTranslation()
    const { mutate: exportSerial, isLoading: loadingExportSerial } =
        useExportSerialMutation()
    const { mutate: importSerialQueue, isLoading } =
        useImportSerialQueueMutation()
    const { mutate: exportSerialQueue, isLoading: loadingExportSerialQueue } =
        useExportSerialQueueMutation()
    const userPermissions = useSelector(selectUserPermissions)

    const isAddSerial = useMemo(() => {
        return permission_addSerial.some((item) =>
            userPermissions.includes(item),
        )
    }, [userPermissions])

    return (
        <ListToolbarWrapper>
            <ActionMenu {...{ openUpload }}>
                <MenuItem
                    onClick={() => exportSerial(payload)}
                    disabled={loadingExportSerial}
                >
                    {loadingExportSerial ? (
                        <HourglassTopIcon />
                    ) : (
                        <DownloadIcon />
                    )}
                    {t('button.export')}
                </MenuItem>
                <MenuItem
                    onClick={exportSerialQueue}
                    disabled={loadingExportSerialQueue}
                >
                    {loadingExportSerialQueue ? (
                        <HourglassTopIcon />
                    ) : (
                        <DownloadIcon />
                    )}
                    {t('button.exportSerialQueue')}
                </MenuItem>
                <MenuItem onClick={() => setOpenUpload(true)}>
                    <UploadIcon />
                    {t('button.importSerialQueue')}
                </MenuItem>
            </ActionMenu>

            <UploadPopup
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                actionImport={importSerialQueue}
                isLoading={isLoading}
            />

            {isAddSerial && (
                <>
                    <ToolBarButton
                        icon={<AddIcon />}
                        handleAction={() => setOpenPopup(true)}
                        name={t('button.add')}
                    />
                    <AddSerialPopup
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    />
                </>
            )}
        </ListToolbarWrapper>
    )
}

SerialListToolbar.propTypes = { payload: PropTypes.object }

export default SerialListToolbar
