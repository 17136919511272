import styled from '@emotion/styled'
import {
    Alert,
    Box,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    TextareaAutosize,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LoadingButton } from '~/components/Button'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import FormAutoSave from '~/components/FinalForm/FormAutoSave'
import PageName from '~/components/PageName'
import Search from '~/components/Search/Search'
import StyledModal from '~/components/StyledModal'
import { DASHBOARD } from '~/constants/Routes'
import {
    useCausesPartIssueDiagnosticQuery,
    useTicketDetailsQuery,
    useTotalLossCheckQuery,
} from '~/pages/Diagnostic/query'
import { selectCustomerComplaintPopup } from '~/store/diagnose/selector'
import { setDiagnosticSerial, setIsOpen } from '~/store/diagnose/slice'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { selectUserPermissions } from '~/store/auth/selector'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { convertBooleanValue, textValidateRegex } from '~/utils/helpers'
import CheckboxesSection from '../CheckboxesSection'
import DeviceHistory from '../DeviceHistory'
import DiagnosticDescription from '../DiagnosticDescription'
import IssueLog from '../IssueLog'
import PartToChange from '../PartToChange'
import PicturesSection from '../PicturesSection'
import RootCauses from '../RootCauses'
import SoftwareDiagnosticsSection from '../SoftwareDiagnosticsSection'
import TechnicianComment from '../TechnicianComment'
import TicketDetails from '../TicketDetails'
import SectionTitle from '../components/SectionTitle'
import {
    CHECKBOX_DIAGNOSTICS,
    LOCALIZATION,
    SOFTWARE_ISSUE,
    STEPS_LENGTH,
} from '../constants'
import {
    useSaveIssueDiagnosticMutation,
    useUpdateIssueDiagnoseMutation,
} from '../mutation'
import { useIssueDiagnoseQuery } from '../query'
import ConfirmationForm from './ConfirmationForm'

const StyledPaper = styled(Paper)(({ theme }) => {
    return {
        borderRadius: 5,
        padding: theme.spacing(2),
        height: '100%',
    }
})

const Section = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'show',
})(({ show }) => {
    return {
        ...(!show && {
            display: 'none',
        }),
    }
})

const DiagnosticDetail = ({ diagnoseId }) => {
    const { issueId, diagnosticId } = useParams()
    diagnoseId = diagnoseId || diagnosticId

    const queryClient = useQueryClient()
    const location = useLocation()
    const searchParams = useMemo(() => {
        return new URLSearchParams(location.search)
    }, [location.search])

    const [showingStep, setShowingStep] = useState(1)
    const [isTriggerTotalLossCheck, setIsTriggerTotalLossCheck] =
        useState(false)
    const [showDiagnosticsSection, setShowDiagnosticsSection] = useState(false)
    const [showDiagnosticsPopup, setShowDiagnosticsPopup] = useState(false)
    const [showFormConfirm, setShowFormConfirm] = useState(false)
    const [showScanEsd, setShowScanEsd] = useState(false)
    const [showMaliciousCondition, setShowMaliciousCondition] = useState(false)
    const [disabledNextButton, setDisabledNextButton] = useState(true)
    const [diagnosticSaveButton, setDiagnosticSaveButton] = useState(false)
    const [finishDiagnostic, setFinishDiagnostic] = useState(false)
    const [isFetchPartCode, setIsFetchPartCode] = useState(false)
    const [generalFormValue, setGeneralFormValue] = useState({})
    const [selectedCauses, setSelectedCauses] = useState([])
    const [clickedCause, setClickedCause] = useState({})
    const [initForm, setInitForm] = useState(true)
    const [scanError, setScanError] = useState(false)

    const { openSnackbar } = useDialogContext()
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)
    const userPermissions = useSelector(selectUserPermissions)
    const [errorPartCodes, setErrorPartCodes] = useState([])
    const [ppidRegex, setPPIDRegex] = useState(null)

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { data, isSuccess, isLoading } = useIssueDiagnoseQuery({
        id: diagnoseId,
    })

    const [isWaterTotalDamaged, setIsWaterTotalDamaged] = useState(
        data?.liquid_damage_total_loss ?? false,
    )

    useTotalLossCheckQuery({
        id: diagnoseId,
        isTriggerTotalLossCheck,
    })

    const { data: ticketData } = useTicketDetailsQuery(issueId)

    const { mutate: saveIssueDiagnose, isLoading: isSaving } =
        useSaveIssueDiagnosticMutation()

    useEffect(() => {
        if (isSuccess) {
            setShowDiagnosticsSection(data?.powers_on)
            setShowingStep(data?.current_step)
            dispatch(setDiagnosticSerial(data?.issue?.name))
            setShowMaliciousCondition(data?.malicious_damage)
            if (data?.finished_at) {
                setFinishDiagnostic(true)
            }
            setPPIDRegex(
                data?.issue?.ticket?.device?.vendor?.ppid_validation_regex,
            )
        }
    }, [data, dispatch, isSuccess])

    const hasAddAdditionalPartsRight =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.diagnose.add_additional_parts')

    const initialValuesForm = useMemo(() => {
        return data ?? {}
    }, [data])

    const handleNextStepPictureSection = useCallback(() => {
        if (showingStep === 1) {
            setDisabledNextButton(true)
            setShowingStep(showingStep + 1)
        }
    }, [showingStep])

    const {
        mutate: updateIssueDiagnoses,
        mutateAsync: updateIssueDiagnosesAsync,
        isLoading: isUpdatingIssueDiagnostic,
    } = useUpdateIssueDiagnoseMutation(
        showingStep,
        setFinishDiagnostic,
        setIsTriggerTotalLossCheck,
    )

    const handleNextStep = useCallback(
        (values) => {
            if (showingStep === 1) setDisabledNextButton(true)

            if (showingStep === 2) {
                setShowDiagnosticsPopup(showDiagnosticsSection)
            }

            if (showingStep >= 2) {
                updateIssueDiagnoses({
                    ...generalFormValue,
                    ...values,
                    currStep: showingStep === 3 ? showingStep : showingStep + 1,
                })
            }

            if (showingStep < STEPS_LENGTH && !isWaterTotalDamaged)
                setShowingStep(showingStep + 1)
        },
        [
            generalFormValue,
            showDiagnosticsSection,
            showingStep,
            updateIssueDiagnoses,
            isWaterTotalDamaged,
        ],
    )

    const updateAsync = async (values) => {
        const prepareDataForUpdating = (values) => {
            const newValue = convertBooleanValue(CHECKBOX_DIAGNOSTICS, values, [
                SOFTWARE_ISSUE,
            ])

            if (
                newValue[SOFTWARE_ISSUE] === true ||
                newValue[SOFTWARE_ISSUE] === 'true' ||
                newValue[SOFTWARE_ISSUE] === 1
            ) {
                newValue[SOFTWARE_ISSUE] = 1
            } else if (
                newValue[SOFTWARE_ISSUE] === false ||
                newValue[SOFTWARE_ISSUE] === 'false' ||
                newValue[SOFTWARE_ISSUE] === 0
            ) {
                newValue[SOFTWARE_ISSUE] = 0
            } else if (
                newValue[SOFTWARE_ISSUE] === 'n/a' ||
                newValue[SOFTWARE_ISSUE] === -1
            ) {
                newValue[SOFTWARE_ISSUE] = -1
            }

            return newValue
        }

        if (!initForm) {
            await updateIssueDiagnosesAsync({
                ...prepareDataForUpdating(values),
                currStep: showingStep > 3 ? 3 : showingStep,
            })
        }

        setInitForm(false)
    }

    const isShowSection = useCallback(
        (containedStep) => showingStep >= containedStep,
        [showingStep],
    )

    const customerComplaintPopup = useSelector(selectCustomerComplaintPopup)

    const handleCloseDiagnosticsPopup = () => {
        setShowDiagnosticsPopup(false)
    }

    const handleCloseFormConfirm = () => {
        setShowFormConfirm(false)
    }

    const handleEnterSearch = (event) => {
        if (
            event.key === 'Enter' &&
            event.target.value &&
            event.target.value !== ''
        ) {
            saveIssueDiagnose(
                {
                    issueDiagnoseId: diagnoseId,
                    esdLocation: event.target.value,
                    hasPart: !_.isEqual(showingPartCodes, []),
                    parts: showingPartCodes,
                },
                {
                    onSuccess: () => {
                        setShowFormConfirm(false)
                        setShowScanEsd(false)
                        setScanError(false)
                        const searchParams = new URLSearchParams(
                            location.search,
                        )
                        searchParams.set(
                            'tag',
                            !_.isEqual(showingPartCodes, []) ? 2 : 3,
                        )

                        queryClient.invalidateQueries(['issue', issueId])

                        queryClient.invalidateQueries(['issue_diagnostic'])

                        queryClient.invalidateQueries(['issue_logs'])

                        queryClient.invalidateQueries(['device_histories'])

                        navigate({ pathname: DASHBOARD })
                    },
                    onError: (e) => {
                        setScanError(
                            e?.response?.data?.message ||
                                e?.message ||
                                t(`${LOCALIZATION}invalidEsdLocation`),
                        )
                    },
                },
            )
        }
    }

    useCausesPartIssueDiagnosticQuery(issueId, isFetchPartCode)

    useEffect(() => {
        if (showingStep >= 3) {
            setIsFetchPartCode(true)
        }
    }, [showingStep])

    const handleFinalStep = useCallback(
        (values) => {
            // if liquid_damage_total_loss is true don't validate the part_code condition to be required
            if (values?.liquid_damage_total_loss && data?.issue) {
                queryClient.removeQueries('issue_diagnostic_confirm_form')

                setShowFormConfirm(true)
                return true
            }

            const haveEmptyConditions = showingPartCodes?.filter((x) =>
                _.isEmpty(x.condition),
            )

            setErrorPartCodes(haveEmptyConditions)
            if (!_.isEmpty(haveEmptyConditions)) {
                openSnackbar({
                    message: t(LOCALIZATION + 'conditionRequired'),
                    type: 'error',
                })
                return false
            }

            const hasManuallyAddedPartAndEmptyAdditionDesc =
                showingPartCodes?.some(
                    (item) =>
                        item.causes &&
                        item.causes.some(
                            (cause) => cause.id === '#' || cause.id === null,
                        ) &&
                        _.isEmpty(item.additional_description),
                )
            if (hasManuallyAddedPartAndEmptyAdditionDesc) {
                openSnackbar({
                    message: t(
                        `${LOCALIZATION}additionalDescriptionRequiredMsg`,
                    ),
                    type: 'error',
                })
                return false
            }

            if (values?.accidental_damage === true) {
                const isMatchAccidental =
                    showingPartCodes?.filter(
                        (partCode) => partCode?.condition === 'accidental',
                    ).length > 0
                if (!isMatchAccidental) {
                    openSnackbar({
                        message: t(`${LOCALIZATION}conditionNotMatch`, {
                            condition: 'accidental',
                        }),
                        type: 'error',
                    })
                    return false
                }
            }

            if (values?.malicious_damage === true) {
                const isMatchMalicious =
                    showingPartCodes?.filter(
                        (partCode) => partCode?.condition === 'malicious',
                    ).length > 0

                if (!isMatchMalicious) {
                    openSnackbar({
                        message: t(`${LOCALIZATION}conditionNotMatch`, {
                            condition: 'malicious',
                        }),
                        type: 'error',
                    })
                    return false
                }
            }

            // HANDLE REQUIRED PPID
            const isEmptyPPID = showingPartCodes?.filter(
                (item) => item.serializable && _.isEmpty(item.old_ppid),
            )

            setErrorPartCodes(isEmptyPPID)
            if (!_.isEmpty(isEmptyPPID)) {
                openSnackbar({
                    message: 'PPID' + t(LOCALIZATION + 'isRequire'),
                    type: 'error',
                })
                return false
            }

            // HANDLE LENGTH PPID
            const invalidMaxAttempPPIDLength = showingPartCodes?.some(
                (item) =>
                    !_.isEmpty(item.old_ppid) &&
                    !textValidateRegex(ppidRegex, item.old_ppid),
            )

            if (invalidMaxAttempPPIDLength) {
                openSnackbar({
                    message: t('message.ppidAttemptedCharacter'),
                    type: 'error',
                })
                return false
            }
            queryClient.removeQueries('issue_diagnostic_confirm_form')
            if (data?.issue) {
                setShowFormConfirm(true)
            }
            return true
        },
        [
            showingPartCodes,
            queryClient,
            data?.issue,
            openSnackbar,
            t,
            ppidRegex,
        ],
    )

    if (searchParams.has('diagnostic') && !diagnoseId) {
        return (
            <Alert severity='error'>{t(`message.diagnosticIncorrect`)}</Alert>
        )
    }

    if (!isLoading && !data) {
        return (
            <Alert severity='error'>
                {t('pages.issues.overview.error_not_found_diagnostic')}
            </Alert>
        )
    }

    return (
        <>
            <PageName name={`Diagnostic for issue ${issueId}`} />
            <StyledModal open={!!customerComplaintPopup}>
                <SectionTitle title={t(LOCALIZATION + `customer_complaint`)} />
                <Divider sx={{ width: '100%' }} />
                <TextareaAutosize
                    disabled
                    minRows={4}
                    maxRows={5}
                    defaultValue={ticketData?.ticket_description}
                    style={{ width: '100%', marginBottom: '10px' }}
                />
                <LoadingButton
                    label='Confirm'
                    onClick={() => {
                        handleNextStepPictureSection()
                        dispatch(setIsOpen(false))
                    }}
                />
            </StyledModal>

            <StyledModal
                open={showDiagnosticsPopup}
                handleClose={handleCloseDiagnosticsPopup}
            >
                <SectionTitle
                    title={t(LOCALIZATION + `software_diagnostics`)}
                />
                <Divider sx={{ width: '100%' }} />
                <Typography m={3}>Run software diagnostics</Typography>
                <LoadingButton
                    label='Confirm'
                    onClick={() => {
                        if (!isWaterTotalDamaged) {
                            setShowingStep(showingStep + 1)
                        }
                        setShowDiagnosticsPopup(false)
                    }}
                />
            </StyledModal>

            <StyledModal
                open={showFormConfirm}
                handleClose={handleCloseFormConfirm}
                stackProps={{
                    sx: {
                        minWidth: 700,
                        maxHeight: '95vh',
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                    },
                }}
            >
                <SectionTitle title={t(`${LOCALIZATION}confirmPopupTitle`)} />

                <ConfirmationForm diagnoseId={diagnoseId} />

                <Stack direction='row' gap={2}>
                    <LoadingButton
                        label={t('button.close')}
                        onClick={handleCloseFormConfirm}
                    />
                    <LoadingButton
                        label={
                            _.isEqual(showingPartCodes, [])
                                ? data?.liquid_damage_total_loss
                                    ? t(`${LOCALIZATION}button.totalLoss`)
                                    : t(`${LOCALIZATION}button.moveToQC`)
                                : t('button.save')
                        }
                        onClick={() => setShowScanEsd(true)}
                    />
                </Stack>
            </StyledModal>

            <StyledModal
                open={showScanEsd}
                handleClose={() => setShowScanEsd(false)}
                stackStyles={{ width: 'auto' }}
            >
                <Search
                    label={t(`${LOCALIZATION}scanEsd`)}
                    autoFocus
                    onKeyPress={handleEnterSearch}
                />
                {isSaving && (
                    <Box sx={{ width: '50%' }}>
                        <LinearProgress />
                    </Box>
                )}
                {scanError && (
                    <ErrorMessageInput>{scanError}</ErrorMessageInput>
                )}
            </StyledModal>

            {!isLoading && (
                <Form
                    onSubmit={updateIssueDiagnoses}
                    initialValues={initialValuesForm}
                    render={({ handleSubmit, values, form }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormAutoSave
                                    debounce={800}
                                    save={updateAsync}
                                />
                                <Grid container spacing={3}>
                                    <Section item xs={12} md={4} show={true}>
                                        <StyledPaper variant='outlined'>
                                            <TicketDetails data={ticketData} />
                                        </StyledPaper>
                                    </Section>

                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={3}>
                                            <Section item xs={12} show={true}>
                                                <StyledPaper variant='outlined'>
                                                    <PicturesSection
                                                        {...{
                                                            setDisabledNextButton,
                                                            isFinish:
                                                                !!data?.finished_at,
                                                            isInPictureStep:
                                                                showingStep ===
                                                                1,
                                                        }}
                                                    />
                                                </StyledPaper>
                                            </Section>
                                            <Section
                                                item
                                                xs={12}
                                                show={isShowSection(2)}
                                            >
                                                <StyledPaper variant='outlined'>
                                                    <CheckboxesSection
                                                        {...{
                                                            setShowDiagnosticsSection,
                                                            setDisabledNextButton,
                                                            form,
                                                            values,
                                                            finishDiagnostic,
                                                            setShowMaliciousCondition,
                                                            setIsWaterTotalDamaged,
                                                            setDiagnosticSaveButton,
                                                        }}
                                                    />
                                                </StyledPaper>
                                            </Section>
                                        </Grid>
                                    </Grid>
                                    <Section
                                        item
                                        xs={12}
                                        md={4}
                                        show={showDiagnosticsSection}
                                    >
                                        <StyledPaper variant='outlined'>
                                            {ticketData?.serial && (
                                                <SoftwareDiagnosticsSection
                                                    deviceSerial={
                                                        ticketData?.serial
                                                    }
                                                />
                                            )}
                                        </StyledPaper>
                                    </Section>
                                    <Section
                                        item
                                        xs={12}
                                        show={
                                            isShowSection(3) &&
                                            !isWaterTotalDamaged
                                        }
                                    >
                                        <StyledPaper variant='outlined'>
                                            <RootCauses
                                                {...{
                                                    values,
                                                    setGeneralFormValue,
                                                    finishDiagnostic,
                                                    form,
                                                }}
                                                fetchedCauseList={
                                                    initialValuesForm?.issue
                                                        ?.causes
                                                }
                                                setSelectedCauses={
                                                    setSelectedCauses
                                                }
                                                setClickedCause={
                                                    setClickedCause
                                                }
                                                showingStep={showingStep}
                                            />
                                        </StyledPaper>
                                    </Section>
                                    <Section
                                        item
                                        xs={12}
                                        md={6}
                                        show={
                                            isShowSection(3) &&
                                            !isWaterTotalDamaged
                                        }
                                    >
                                        <StyledPaper variant='outlined'>
                                            <DiagnosticDescription
                                                value={selectedCauses}
                                            />
                                        </StyledPaper>
                                    </Section>
                                    <Section
                                        item
                                        xs={12}
                                        md={6}
                                        show={
                                            isShowSection(3) &&
                                            !isWaterTotalDamaged
                                        }
                                    >
                                        <StyledPaper variant='outlined'>
                                            <TechnicianComment
                                                {...{ finishDiagnostic }}
                                            />
                                        </StyledPaper>
                                    </Section>
                                    <Section
                                        item
                                        xs={12}
                                        show={
                                            isShowSection(3) &&
                                            !isWaterTotalDamaged
                                        }
                                    >
                                        <StyledPaper variant='outlined'>
                                            <PartToChange
                                                {...{
                                                    clickedCause,
                                                    showMaliciousCondition,
                                                    finishDiagnostic,
                                                    form,
                                                    hasAddAdditionalPartsRight,
                                                    errorPartCodes,
                                                    ppidRegex,
                                                }}
                                            />
                                        </StyledPaper>
                                    </Section>
                                    {showingStep < STEPS_LENGTH &&
                                        !isWaterTotalDamaged &&
                                        !finishDiagnostic && (
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction='row-reverse'
                                                    spacing={2}
                                                    mt={1}
                                                >
                                                    <LoadingButton
                                                        disabled={
                                                            disabledNextButton
                                                        }
                                                        type='submit'
                                                        label='Next'
                                                        onClick={() =>
                                                            handleNextStep(
                                                                values,
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </Grid>
                                        )}
                                    {(showingStep >= STEPS_LENGTH ||
                                        isWaterTotalDamaged) &&
                                        !finishDiagnostic && (
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction='row-reverse'
                                                    spacing={2}
                                                    mt={1}
                                                >
                                                    <LoadingButton
                                                        disabled={
                                                            diagnosticSaveButton
                                                        }
                                                        onClick={() =>
                                                            handleFinalStep(
                                                                values,
                                                            )
                                                        }
                                                        loading={
                                                            isUpdatingIssueDiagnostic
                                                        }
                                                        label='Save'
                                                    />
                                                </Stack>
                                            </Grid>
                                        )}
                                    <Section item xs={12} show={true}>
                                        <StyledPaper variant='outlined'>
                                            <IssueLog />
                                        </StyledPaper>
                                    </Section>
                                    <Section item xs={12} show={true}>
                                        <StyledPaper variant='outlined'>
                                            <DeviceHistory />
                                        </StyledPaper>
                                    </Section>
                                </Grid>
                            </form>
                        )
                    }}
                />
            )}
        </>
    )
}

DiagnosticDetail.propTypes = {
    diagnoseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DiagnosticDetail
