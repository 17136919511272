import { Checkbox, Link, Typography } from '@mui/material'
import { format } from 'date-fns'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { formatInTimeZone } from 'date-fns-tz'
import { IMPERSONATE_TOKEN } from '~/constants/constants'

/**
 * Truncate file name if length > 50
 * @param {string} fileName File name to be truncated
 * @returns {string} Truncated file name
 */
export const truncateFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf('.')
    const extension = fileName.substring(extensionIndex)
    const fileNameWithoutExtension = fileName.substring(0, extensionIndex)

    if (fileNameWithoutExtension.length > 50) {
        return (
            fileNameWithoutExtension.substring(0, 47) +
            '...' +
            fileNameWithoutExtension.substring(
                fileNameWithoutExtension.length - 3,
            ) +
            extension
        )
    } else {
        return fileName
    }
}

/**
 * Capitalized a word
 * @param {String} str Word to be capitalized
 * @returns {String} Capitalized word
 */
export const capitalize = (str) => {
    if (!str) {
        return ''
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Titleized a sentence
 * @param {String} str Sentence to be titleized
 * @returns {String} Titleized sentence
 */
export const titleize = (str) => {
    if (!str) {
        return ''
    }
    return str
        .split(' ')
        .map((t) => capitalize(t))
        .join(' ')
}

/**
 * Converted 1, "1" to True; 0, "0" to False
 * @param {String|Number} number value to be converted
 * @returns {Boolean} Returned Boolean value
 */
export const renderBooleanValue = (number) => {
    return capitalize((!!number).toString())
}

/**
 * Remove all null or undefined values from an object.
 * @param {Object} obj Object to be removed of null value
 * @returns {Object} Object has been removed null value
 */
export const removeEmptyValue = (obj) => {
    const tmp = { ...obj }

    Object.keys(obj).forEach((k) => {
        if (!obj[k] || String(obj[k]).trim().length === 0) {
            delete tmp[k]
        }
    })

    return tmp
}

/**
 * Convert all keys listed in neededArray of obj to boolean value
 * @param {Array} neededArray Array consists of key need to be converted
 * @param {Object} obj Object to be converted
 * @param {Array} excludeItems Exclude item from the conversion
 * @returns {Object} Converted Object
 */
export const convertBooleanValue = (neededArray, obj, excludeItems) => {
    Object.keys(obj).forEach((k) => {
        if (
            typeof excludeItems === 'undefined' ||
            !Array.isArray(excludeItems) ||
            !excludeItems.includes(k)
        ) {
            if (neededArray.includes(k)) {
                if (obj[k] === 'false') {
                    obj[k] = false
                }

                if (obj[k] !== null) obj[k] = !!obj[k]
            }
        }
    })

    return obj
}

export const getValidator = (isRequired, fieldName) =>
    isRequired
        ? (value) => (value ? undefined : `${fieldName} is a required field`)
        : () => {}

/**
 * Render into corresponding React component
 * @param {Function} navigate useNavigate function
 * @param {Object} item Item to be rendered
 * @returns {ReactComponent} Rendered React component
 */
export const getValue = (navigate, item) => {
    if (item.type === 'boolean') {
        return <Checkbox sx={{ padding: 0 }} checked={item.value} disabled />
    }
    if (item.type === 'date') {
        return (
            <Typography align='left'>
                <DateTimeLocale datetime={item?.value} />
            </Typography>
        )
    }
    if (item.clickable && item.navigateLink) {
        return (
            <Link
                component='button'
                variant='inherit'
                to='#'
                underline='hover'
                onClick={() => navigate(item.navigateLink)}
            >
                <Typography align='left'>{item.value}</Typography>
            </Link>
        )
    }
    return <Typography align='left'>{item.value}</Typography>
}

/**
 * Calculate the number of business day (Mon-Fri) in a time period
 * @param {Date} startDate Starting date
 * @param {Date} endDate Ending date
 * @returns {Date} Calculated number of business dates
 */
export const calculateBusinessDays = (startDate, endDate) => {
    if (endDate < startDate) return 0

    // Calculate days between dates
    const millisecondsPerDay = 86400 * 1000 // Day in milliseconds
    startDate.setHours(0, 0, 0, 1) // Start just after midnight
    endDate.setHours(23, 59, 59, 999) // End just before midnight
    const diff = endDate - startDate // Milliseconds between datetime objects
    let days = Math.ceil(diff / millisecondsPerDay)

    // Subtract two weekend days for every week in between
    const weeks = Math.floor(days / 7)
    days = days - weeks * 2

    // Handle special cases
    const startDay = startDate.getDay()
    const endDay = endDate.getDay()

    // Remove weekend not previously removed.
    if (startDay - endDay > 1) {
        days = days - 2
    }

    // Remove start day if span starts on Sunday but ends before Saturday
    if (startDay === 0 && endDay !== 6) {
        days = days - 1
    }

    // Remove end day if span ends on Saturday but starts after Sunday
    if (endDay === 6 && startDay !== 0) {
        days = days - 1
    }

    return days
}

/**
 * Calculate the number of day in a time period
 * @param {Date} startDate Starting date
 * @param {Date} endDate Ending date
 * @returns {Date} Calculated number of dates
 */
export const calculateDays = (startDate, endDate) => {
    if (endDate < startDate) return 0

    // Calculate days between dates
    const millisecondsPerDay = 86400 * 1000 // Day in milliseconds
    startDate.setHours(0, 0, 0, 1) // Start just after midnight
    endDate.setHours(23, 59, 59, 999) // End just before midnight
    const diff = endDate - startDate // Milliseconds between datetime objects
    return Math.ceil(diff / millisecondsPerDay)
}

/**
 * Format given date time string to a new format
 * @param {string} dateTime String representation of date time
 * @param {string} toFormat Format that want to format the date
 * @returns {string} Formatted date time
 */
export const formatDateTime = (
    dateTime,
    toFormat = 'yyyy-MM-dd',
    timezone = 'Europe/Amsterdam',
    formatHour = false,
) => {
    const hour = !formatHour ? ` HH:mm:ss` : ''
    return dateTime
        ? !formatHour
            ? formatInTimeZone(
                  new Date(dateTime),
                  timezone,
                  `${toFormat}${hour} (zzz)`,
              )
            : format(new Date(dateTime), `${toFormat}${hour}`)
        : 'N/A'
}

/**
 * Find the latest date in the given array of dates
 * @param {Array<String>} dates Array of dateTime string
 * @returns {string} The latest date
 */
export const getMaxDate = (dates) => {
    const maxDate = new Date(
        Math.max(
            ...dates.map((element) => {
                return new Date(element)
            }),
        ),
    )

    const year = maxDate.getFullYear()

    if (year === 1970) {
        return 'Never'
    }

    return format(new Date(maxDate), 'yyyy-MM-dd hh:mm a')
}

/**
 * Convert image from base64 string to Blob
 * @param {string} data Base64 representation of an image
 * @returns {Promise<Blob>} Blob of image
 */
export const base64ImageToBlob = async (data) =>
    await (await fetch(data)).blob()

/**
 * Get external link to easy4u to the pased Ticket ID
 * @param {String|Number} ticketID Ticket ID
 * @returns External link to easy4u to the pased Ticket ID
 */
export const ticketExternalLink = (ticketID, url = 'https://easy4u.nl/') => {
    const env = import.meta.env.VITE_ENVIRONMENT

    if (env === 'production') {
        return `${url}admin/tickets/ticket/${ticketID}`
    }

    return `https://staging.easy4u.nl/admin/tickets/ticket/${ticketID}`
}

/**
 * Get external link to easy4u for printing Serial
 * @param {String|Number} serviceId Serial Number
 * @returns External link to easy4u for printing Serial
 */
export const serialPrintLink = (serviceId, url = 'https://easy4u.nl/') => {
    const env = import.meta.env.VITE_ENVIRONMENT

    if (env === 'production') {
        return `${url}admin/netbooks/printAltecSerienummer?ref=${serviceId}`
    }

    return `https://staging.easy4u.nl/admin/netbooks/printAltecSerienummer?ref=${serviceId}`
}

/**
 * Get external link to easy4u for printing Service ID
 * @param {String|Number} serviceId Service ID
 * @returns External link to easy4u for printing Service ID
 */
export const serviceIdPrintLink = (serviceId, url = 'https://easy4u.nl/') => {
    const env = import.meta.env.VITE_ENVIRONMENT

    if (env === 'production') {
        return `${url}admin/netbooks/printAltecLabel?ref=${serviceId}`
    }

    return `https://staging.easy4u.nl/admin/netbooks/printAltecLabel?ref=${serviceId}`
}

/**
 * @callback beforePrint
 * @param  {object} printElement printElement (iframe)
 */

/**
 * Print element by using its HTML ID
 * @param {string|number} componentId HTML ID of element wanted to print
 * @param {Function} beforePrint Execute before print to further customize print function
 * @param {Array} additionalCss Additional CSS to add to print
 */
export const idElementPrint = (
    componentId,
    beforePrint = () => {},
    additionalCss = [],
    beforePrintListener = null,
    afterPrintListener = null,
) => {
    const printElement = document.getElementById('printer')
    const fromElement = document.getElementById(componentId)

    const addCss = additionalCss.join('\n')
    const allCSS = [...document.styleSheets]
        .map((styleSheet) => {
            try {
                return [...styleSheet.cssRules]
                    .map((rule) => rule.cssText)
                    .join('')
            } catch (e) {
                return ''
            }
        })
        .filter(Boolean)
        .join('\n')
        .concat('.wont-print { display: none }\n')
        .concat(addCss)

    const iframeContentWindow = printElement.contentWindow
    const iframeDocument = iframeContentWindow.document
    iframeDocument.body.innerHTML =
        fromElement.innerHTML + `<style>${allCSS}</style>`

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href =
        'https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0'

    iframeDocument.head.append(link)
    beforePrint(printElement)

    iframeContentWindow.addEventListener('beforeprint', () => {
        if (typeof beforePrintListener === 'function') {
            beforePrintListener()
        }
    })

    iframeContentWindow.addEventListener('afterprint', () => {
        if (typeof afterPrintListener === 'function') {
            afterPrintListener()
        }
    })

    iframeContentWindow.print()
    iframeContentWindow.close()
}

export const isNumber = (value) => {
    return typeof value === 'number'
}

export const generateRandomString = (max) => {
    if (max <= 0) return ''
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < max; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length),
        )
    }
    return result
}

export const parseApiErrorMessage = (err) => {
    if (err === null) {
        return ''
    }

    if (err.response?.data?.errors) {
        const errors = err.response.data.errors
        // parse laravel validation error message
        return Object.keys(errors)
            .map((key) => errors[key])
            .map((item) => {
                if (Array.isArray(item) && item.length > 0) {
                    return item[0]
                }

                return item
            })
            .join('\n')
    }

    if (err.response?.data?.message) {
        return err.response.data.message
    }

    return err.message
}

// Routes Helper
export function route(name, params = {}) {
    return Object.entries(params).reduce(
        (carry, [key, value]) => carry.replace(`:${key}`, value),
        name,
    )
}

export const failedToFetched = (error, openSnackbar) => {
    let message = 'failed to fetch!'
    if (error.response.data.message) {
        message = error.response.data.message
    } else if (error.message) {
        message = error.message
    }
    openSnackbar({
        message,
        type: 'error',
        duration: 10000,
    })
}

export const formatDateToYYYYMMDD = (dateString) => {
    const date = new Date(dateString)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const datesLocaleFormat = {
    en: 'MM-dd-yyyy',
    nl: 'dd-MM-yyyy',
    fr: 'dd-MM-yyyy',
    de: 'yyyy-MM-dd',
}

export const textValidateRegex = (regexString, stringToCheck) => {
    const regex = new RegExp(regexString)
    return regex.test(stringToCheck)
}

/**
 * Get external link to easy4u for printing servicebon through Issue QC
 * @param {String|Number} ticketId Ticket ID
 * @returns External link to easy4u for printing servicebon
 */
export const serviceBonPrintLink = (ticketId, url) => {
    const env = import.meta.env.VITE_ENVIRONMENT
    const serviceBonLink = import.meta.env.VITE_SERVICEBON_URL

    if (env === 'production') {
        return `${url}admin/tickets/print-service-bon/${ticketId}`
    }

    return route(serviceBonLink, { id: ticketId })
}

export const isProductionApp = import.meta.env.VITE_ENVIRONMENT === 'production'

export const getImpersonateToken = () => {
    return localStorage.getItem(IMPERSONATE_TOKEN)
}
