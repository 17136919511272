import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'

const SUPER_ADMIN = 'super_admin'
const ISSUE_CLOSE = 'issues.close'
const ADMINISTRATOR_USER_IMPERSONATE = 'administrator.users.impersonate'

const usePermissions = () => {
    const userPermissions = useSelector(selectUserPermissions)

    const hasOneOfPermissions = (requiredPermissions) => {
        if (userPermissions.includes(SUPER_ADMIN)) {
            return true
        }

        if (Array.isArray(requiredPermissions)) {
            return !!requiredPermissions.some((permission) =>
                userPermissions.includes(permission),
            )
        }

        return !!userPermissions.includes(requiredPermissions)
    }

    const permissions = {
        issue: {
            canCloseIssue: hasOneOfPermissions([ISSUE_CLOSE]),
        },
        administrator: {
            user: {
                canImpersonateUser: hasOneOfPermissions([
                    ADMINISTRATOR_USER_IMPERSONATE,
                ]),
            },
        },
    }

    return {
        permissions,
    }
}

export default usePermissions
