import diagnosticApi from '~/api/diagnosticApi'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { base64ImageToBlob } from '~/utils/helpers'

export const usePictureMutation = () => {
    const { issueId } = useParams()
    const queryClient = useQueryClient()
    return useMutation(
        ['diagnostic_picture', issueId],
        async (params) => {
            const formData = new FormData()
            let i = 0
            for (const item of params) {
                let imgBlob = item.image
                if (!item?.upload) {
                    imgBlob = await base64ImageToBlob(item.image)
                }
                formData.append(`pictures[${i}]`, imgBlob)
                formData.append(`dispatch_enabled[${i}]`, item.checked)
                ++i
            }

            const { data } = await diagnosticApi.uploadPictures(
                issueId,
                formData,
            )
            return data
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('issue')
                queryClient.invalidateQueries('diagnostic_picture')
            },
            onError: () => {},
            retry: false,
        },
    )
}
export const useRemovePictureMutate = () => {
    const { issueId } = useParams()
    return useMutation(
        ['diagnostic_picture_remove', issueId],
        async (pictureId) => {
            return await diagnosticApi.removePictures(pictureId)
        },
        {
            retry: false,
        },
    )
}

export const useUpdateIssueDiagnoseMutation = (
    showingStep,
    setFinishDiagnostic,
    setIsTriggerTotalLossCheck,
) => {
    const { issueId } = useParams()
    const { openSnackbar } = useDialogContext()
    const showingPartCodes = useSelector(selectShowedPartCodeForDiagnostic)
    let isUpdated = false

    return useMutation(
        ['create_issue_diagnose', issueId],
        async (values) => {
            if (!values.finished_at) {
                const validCauses = values?.causes?.filter(
                    (x) => x.value === true,
                )

                const comments = values?.comments || null

                let updatedShowingPartCodes = [...showingPartCodes]
                if (
                    showingPartCodes.length > 0 &&
                    values.malicious_damage === false
                ) {
                    updatedShowingPartCodes = showingPartCodes.map((part) => {
                        if (part.broken === true) {
                            return {
                                ...part,
                                broken: false,
                            }
                        }
                        return part
                    })
                }

                const payload = {
                    ...values,
                    causes: validCauses,
                    issue_id: issueId,
                    comments,
                    current_step: values?.currStep ?? showingStep,
                    parts: updatedShowingPartCodes,
                }

                isUpdated = true
                await diagnosticApi.updateIssueDiagnose(values.id, payload)
            }

            return isUpdated
        },
        {
            onSuccess: (data) => {
                if (data.finished_at) {
                    setFinishDiagnostic(true)
                    setIsTriggerTotalLossCheck(true)
                }
            },
            onError: (error) => {
                let message = 'failed to save!'
                if (error.response.data.message) {
                    message = error.response.data.message
                } else if (error.message) {
                    message = error.message
                }
                openSnackbar({
                    message,
                    type: 'error',
                    duration: 10000,
                })
            },
            retry: false,
        },
    )
}

export const useSaveIssueDiagnosticMutation = () => {
    return useMutation(
        async ({ issueDiagnoseId, esdLocation, hasPart }) => {
            return await diagnosticApi.saveIssueDiagnose(
                issueDiagnoseId,
                esdLocation,
                hasPart,
            )
        },
        {
            retry: false,
        },
    )
}

export const useDispatchPictureMutation = () => {
    const { issueId } = useParams()
    const queryClient = useQueryClient()
    const { openSnackbar } = useDialogContext()
    return useMutation(
        ['diagnostic_dispatch_picture', issueId],
        async (params) => {
            const { data } = await diagnosticApi.updateDispatchPictures(
                issueId,
                params,
            )
            return data
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('diagnostic_picture')
            },
            onError: (error) => {
                openSnackbar({
                    message: error.message,
                    type: 'error',
                })
            },
            retry: false,
        },
    )
}

export const useGetRootCausesBySerialAndPartCodesMutation = (locale) => {
    return useMutation(
        async ({ serial, partCodes, search }) =>
            await diagnosticApi.getRootCausesBySerialAndPartCodes({
                serial,
                partCodes,
                search,
                locale,
            }),
    )
}
