import { useTranslation } from 'react-i18next'
import BreadCrumb from '~/components/BreadCrumb'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DATASCHEDULER,
    ADMINISTRATOR_DATASCHEDULER_NEW,
} from '~/constants/Routes'
import PageName from '~/components/PageName'
import ListToolbar from '~/components/ListToolbar'
import { SUPER_ADMIN } from '~/constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import { useEffect, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { selectSnackbar } from '~/store/snackbar/selector'
import { useSnackbar } from '~/hooks/useSnackbar'
import { unsetSnackbar } from '~/store/snackbar/slice'
import Table from './Table'
import ComponentTooltip from '~/components/ComponentTooltip'

const permissions = [SUPER_ADMIN]

const DataScheduler = () => {
    const { t } = useTranslation()
    const userPermissions = useSelector(selectUserPermissions)
    const snackbarData = useSelector(selectSnackbar)
    const { showSnackbar, snackbar } = useSnackbar()
    const dispatch = useDispatch()
    useEffect(() => {
        if (snackbarData) {
            showSnackbar(snackbarData.message, snackbarData.type)
        }
        return () => {
            dispatch(unsetSnackbar({}))
        }
    })

    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DATASCHEDULER,
            title: 'dataScheduler',
        },
    ]

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ADMINISTRATOR_DATASCHEDULER_NEW,
                icon: <AddIcon />,
                isPermission: () => {
                    return permissions.some((item) =>
                        userPermissions.includes(item),
                    )
                },
            },
        ]
    }, [userPermissions])

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DATASCHEDULER}
            >
                <div>
                    <PageName name={t(`breadCrumb.dataScheduler`)} />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={toolBarButtons} />
            <Table />
        </>
    )
}

export default DataScheduler
