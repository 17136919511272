import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DataNotFound from '~/components/DataNotFound'
import PaginationTable from '~/components/PaginationTable'
import SearchToolBar from '~/components/SearchToolBar'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import { SUPER_ADMIN } from '~/constants/constants'
import useTable from '~/hooks/useTable'
import { selectUser, selectUserPermissions } from '~/store/auth/selector'
import { ComponentHeadCells, ComponentUnlinkedHeadCells } from './constants'
import { useLinkComponentToProductMutation } from './mutation'
import { useComponentsNotLinkedQuery } from './query'
import { LoadingButton } from '~/components/Button'
import PropTypes from 'prop-types'

const localization = 'pages.components.'
const permissionForLinkComponent = [
    SUPER_ADMIN,
    'parts.products.link_component',
]

const ComponentTableBody = ({ isLoading, components }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const user = useSelector(selectUser)
    const userPermissions = useSelector(selectUserPermissions)
    const hasPermission = useMemo(() => {
        return permissionForLinkComponent.some((item) =>
            userPermissions.includes(item),
        )
    }, [userPermissions])

    const { mutate: linkComponent, isLoading: isLinkLoading } =
        useLinkComponentToProductMutation()

    const handleLinkComponent = (productComponentId) => {
        linkComponent({
            component_id: productComponentId,
            product_id: id,
        })
    }

    if (isLoading) {
        return <TableLoading colSpan={ComponentHeadCells.length} />
    }

    return (
        <TableBody>
            {components.length > 0 ? (
                components.map((row) => {
                    const votePlural =
                        row?.component_votes.length === 1 ? ' vote ' : ' votes '
                    const currentVotes = row?.component_votes.filter(
                        (x) => x?.created_by === user?.id,
                    ).length
                    return (
                        <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell>{row.part_number}</TableCell>
                            <TableCell>
                                <LoadingButton
                                    loading={isLinkLoading}
                                    disabled={
                                        currentVotes >=
                                            row?.component_votes_max_per_user &&
                                        !hasPermission
                                    }
                                    label={
                                        hasPermission
                                            ? t(
                                                  `pages.components.linkComponent`,
                                              )
                                            : t(
                                                  `pages.components.voteComponent`,
                                              )
                                    }
                                    onClick={() => {
                                        handleLinkComponent(row?.id)
                                    }}
                                />
                                {row?.component_votes.length > 0
                                    ? ' ' +
                                      row?.component_votes.length +
                                      votePlural
                                    : ''}
                            </TableCell>
                        </TableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={ComponentHeadCells.length} />
            )}
        </TableBody>
    )
}

ComponentTableBody.propTypes = {
    isLoading: PropTypes.bool,
    components: PropTypes.arrayOf(PropTypes.object),
}

const ListComponentsNotLinked = () => {
    const {
        data,
        isLoading,
        order,
        orderBy,
        sortHandler,
        page,
        changePageHandler,
        search,
        searchHandler,
        onEnterSearch,
    } = useTable({
        query: useComponentsNotLinkedQuery,
        initialStates: {
            order: 'asc',
        },
        queryAttributes: {},
    })

    const components = useMemo(() => {
        return data?.data || []
    }, [data])

    return (
        <>
            <SearchToolBar
                filterSearch={search}
                onFilterSearch={searchHandler}
                sx={{
                    width: '100%',
                }}
                onEnterSearch={onEnterSearch}
            />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 400 }}
                        aria-label='collapsible table'
                        size='medium'
                    >
                        <TableHeader
                            headCells={ComponentUnlinkedHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />

                        <ComponentTableBody
                            isLoading={isLoading}
                            components={components}
                        />
                    </Table>
                </TableContainer>

                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}

export default ListComponentsNotLinked
