import MasterLayout from '~/components/MasterLayout'
import ScrollUpButton from '~/components/ScrollUpButton'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import StyledDialogProvider from './components/providers/StyledDialogProvider'
import store from './store'
import ThemeProvider from './theme'
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

export default function App() {
    const { i18n } = useTranslation()

    const locale = localStorage.getItem('LOCALE')

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale, i18n])

    useEffect(() => {
        const handleMessage = (event) => {
            const regex = /^openNewTab\|(https?:\/\/[^\s/$.?#].\S*)$/
            const match = regex.exec(event.data)
            if (match) {
                window.open(match[1], '_blank')
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    const getAvailableCameras = useCallback(async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices()
            const cameras = devices.filter(
                (device) => device.kind === 'videoinput',
            )
            localStorage.setItem('CAMERA_LIST', JSON.stringify(cameras))
            if (cameras.length === 0) {
                localStorage.removeItem('SELECTED_CAMERA')
                return
            }

            const storedCamera = JSON.parse(
                localStorage.getItem('SELECTED_CAMERA'),
            )

            if (!storedCamera || !isCameraAvailable(cameras, storedCamera)) {
                localStorage.setItem(
                    'SELECTED_CAMERA',
                    JSON.stringify(cameras[0]),
                )
            }
        } catch (error) {
            console.error('Error getting available cameras:', error)
        }
    }, [])

    const isCameraAvailable = (cameras, storedCamera) => {
        return cameras.some(
            (camera) => camera.deviceId === storedCamera.deviceId,
        )
    }

    useEffect(() => {
        getAvailableCameras()
        const intervalId = setInterval(getAvailableCameras, 5000) // Call the API every 5 seconds

        return () => {
            clearInterval(intervalId) // Clean up the interval when the component is unmounted
        }
    }, [getAvailableCameras])

    return (
        // <React.StrictMode>
        <Provider store={store}>
            <StyledDialogProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <ThemeProvider>
                            <MasterLayout />
                            <ScrollUpButton />
                        </ThemeProvider>
                    </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </StyledDialogProvider>
        </Provider>
        // </React.StrictMode>
    )
}
