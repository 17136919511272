import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    tableContainer: {
        overflow: 'auto',
        border: '1px solid lightgrey',
    },
    tableHead: {
        background: '#d8d8d8',
    },
    tableCell: {
        textAlign: 'center',
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        fontSize: '0.875rem',
        padding: 'none',
    },
    sectionTitle: { width: '100%', textAlign: 'center' },
    softwareDiagnosticsSection: {
        paper: { padding: 2 },
    },
}))

export default useStyles
