import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { SUPER_ADMIN } from '~/constants/constants'
import {
    ISSUE_ISSUE,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_SCAN_NEW,
    ISSUE_RACKSCAN_TICKET,
} from '~/constants/Routes'
import { useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import ListToolbar from '~/components/ListToolbar'
import Table from './Table'
import ComponentTooltip from '~/components/ComponentTooltip'

const permissions = [SUPER_ADMIN]

const Scan = () => {
    const userPermissions = useSelector(selectUserPermissions)

    const BreadComponents = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_RACKSCAN_TICKET, title: 'rackScans' },
        {
            route: '#',
            title: 'scan',
        },
    ]

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ISSUE_RACKSCAN_SCAN_NEW,
                icon: <AddIcon />,
                isPermission: () => {
                    return permissions.some((item) =>
                        userPermissions.includes(item),
                    )
                },
            },
        ]
    }, [userPermissions])
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ISSUE_RACKSCAN_SCAN}
            >
                <div>
                    <PageName name={'Scans'} />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={toolBarButtons} />
            <Table />
        </>
    )
}
export default Scan
