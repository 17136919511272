import DownloadIcon from '@mui/icons-material/Download'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { MenuItem } from '@mui/material'
import ActionMenu from '~/components/ActionMenu'
import { LoadingButton } from '~/components/Button'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import { ISSUE_DISPATCH_ADD } from '~/constants/Routes'
import { SUPER_ADMIN } from '~/constants/constants'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectUserPermissions } from '~/store/auth/selector'
import { LOCALIZATION } from './constants'
import { useExportDispatchMutation } from './mutate'
import PropTypes from 'prop-types'

const permissions = [SUPER_ADMIN, 'issues.dispatches.create']

const DispatchListToolbar = ({ payload }) => {
    const { mutate: exportDispatch, isLoading } = useExportDispatchMutation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userPermissions = useSelector(selectUserPermissions)
    const hasPermission = permissions.some((item) =>
        userPermissions.includes(item),
    )

    const handleAddDispatch = () => {
        navigate(ISSUE_DISPATCH_ADD)
    }

    return (
        <ListToolbarWrapper>
            <ActionMenu>
                <MenuItem
                    onClick={() => exportDispatch(payload)}
                    disabled={isLoading}
                >
                    {isLoading ? <HourglassTopIcon /> : <DownloadIcon />}
                    {t('button.export')}
                </MenuItem>
            </ActionMenu>
            {hasPermission && (
                <LoadingButton
                    label={t(`${LOCALIZATION}add_dispatch`)}
                    onClick={handleAddDispatch}
                />
            )}
        </ListToolbarWrapper>
    )
}

DispatchListToolbar.propTypes = { payload: PropTypes.object }

export default DispatchListToolbar
