import { Typography } from '@mui/material'
import FormBodyWrapper from '~/components/FormBodyWrapper'
import useServiceCenterFormData from '../hooks/useServiceCenterFormData'
import ServiceCenterForm from '../ServiceCenterForm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'

function Body({ data, localization }) {
    const userPermissions = useSelector(selectUserPermissions)
    const hasEditServiceCenterPermission =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('administrator.definitions.service_center')

    const { InputFieldGroup, selectGroup, branchesList } =
        useServiceCenterFormData()
    const { t } = useTranslation()
    return (
        <FormBodyWrapper>
            {data && hasEditServiceCenterPermission ? (
                <ServiceCenterForm
                    {...{
                        localization,
                        InputFieldGroup,
                        selectGroup,
                        branchesList,
                    }}
                />
            ) : (
                <Typography>{t(`message.data_not_found`)}</Typography>
            )}
        </FormBodyWrapper>
    )
}

Body.propTypes = { data: PropTypes.any, localization: PropTypes.string }

export default Body
