import styled from '@emotion/styled'
import { Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '~/components/Button'
import Loading from '~/components/Loading'
import StyledModal from '~/components/StyledModal'
import QCFormSection from '../QCFormSection'
import { useIssueQualityCheckForFormQuery } from '../query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const Section = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'show',
})(({ show }) => {
    return {
        ...(!show && {
            display: 'none',
        }),
    }
})

const QcConfirmationForm = ({
    open,
    setOpen,
    handleSave,
    isSavingIssueQc,
    qcDataOverrule,
    qcOverruleComment,
    qcOverrulePictures,
    isConfirmOverrule = false,
}) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'button',
    })
    const { issueId: id } = useParams()
    const { data, isLoading, isFetched } = useIssueQualityCheckForFormQuery(id)
    const areAllAnswersTrue = useMemo(() => {
        if (isFetched) {
            const { answers } = data.data
            return Object.values(answers).every(
                (answer) => answer === 'true' || answer === true,
            )
        }
        return false
    }, [data, isFetched])

    return (
        <StyledModal
            open={open}
            handleClose={() => setOpen(false)}
            stackProps={{
                sx: {
                    minWidth: 850,
                    maxHeight: '95vh',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                },
            }}
        >
            {isLoading ? (
                <Loading></Loading>
            ) : (
                <>
                    <Box id='qc-confirm-form'>
                        <QCFormSection
                            qcData={data}
                            isFetchedData={true}
                            qcConfirmDataOverrule={qcDataOverrule}
                            qcConfirmComment={qcOverruleComment}
                            qcConfirmPictures={qcOverrulePictures}
                            isConfirmOverrule={isConfirmOverrule}
                            isConfirmForm
                        ></QCFormSection>
                    </Box>
                    <Section item xs={12} show={true}>
                        <Box
                            sx={{
                                pt: 2,
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            <LoadingButton
                                label={
                                    areAllAnswersTrue ? t('accept') : t('deny')
                                }
                                loading={isSavingIssueQc}
                                onClick={handleSave}
                                bgColor={
                                    areAllAnswersTrue ? '#76B72A' : '#d32f2f'
                                }
                            />
                            <LoadingButton
                                label={t('close')}
                                loading={isSavingIssueQc}
                                onClick={() => setOpen(false)}
                                bgColor='gray'
                            />
                        </Box>
                    </Section>
                </>
            )}
        </StyledModal>
    )
}

QcConfirmationForm.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    isSavingIssueQc: PropTypes.bool.isRequired,
    qcDataOverrule: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    qcOverruleComment: PropTypes.string,
    qcOverrulePictures: PropTypes.arrayOf(PropTypes.object),
    isConfirmOverrule: PropTypes.bool,
}

export default QcConfirmationForm
