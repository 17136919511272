import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { IconButton } from '@mui/material'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { DISPATCH_FAILED } from '../Detail/constants'
import EditStockLabelPopup from './EditStockLabelPopup'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

const useStyle = makeStyles()(() => ({
    hidden: {
        visibility: 'hidden',
    },
}))
const StockLabelDisplayer = ({ bomId, stockLabel, bomState }) => {
    const { classes } = useStyle()
    const userPermissions = useSelector(selectUserPermissions)
    const [open, setOpen] = useState(false)
    const isFailed = bomState === DISPATCH_FAILED
    const isHavePermissionToEdit =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.bom.edit.stock_label')
    const handleOpenModal = useCallback(() => {
        setOpen(true)
    }, [])

    return (
        <>
            {stockLabel || 'N/A'}
            <IconButton
                className={clsx(
                    !(isFailed && isHavePermissionToEdit) && classes.hidden,
                )}
                size='small'
                onClick={() => handleOpenModal()}
            >
                <BorderColorIcon color='primary' />
            </IconButton>

            {open && (
                <EditStockLabelPopup
                    message='Edit Stock label Information'
                    open={open}
                    onClose={() => setOpen(false)}
                    bomId={bomId}
                    stockLabel={stockLabel}
                    bomState={bomState}
                />
            )}
        </>
    )
}

StockLabelDisplayer.propTypes = {
    bomId: PropTypes.number,
    stockLabel: PropTypes.string,
    bomState: PropTypes.string,
}

export default StockLabelDisplayer
