import BreadCrumb from '~/components/BreadCrumb'
import PageName from '~/components/PageName'
import { ISSUE_BOM } from '~/constants/Routes'
import BomTable from '~/pages/BOM/Overview/BomTable'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import { useTranslation } from 'react-i18next'

const breadCrumbConfig = [
    { route: ISSUE_BOM, title: 'BOM' },
    { route: '#', title: 'BOM' },
]

const BOMOverview = () => {
    const userPermissions = useSelector(selectUserPermissions)
    const { t } = useTranslation()
    const hasViewBomRight =
        userPermissions.includes('super_admin') ||
        userPermissions.includes('issues.bom.overview')

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            {hasViewBomRight ? (
                <>
                    <PageName name={'BOM'} />
                    <BomTable />
                </>
            ) : (
                <h2>{t(`message.you_do_not_have_permission`)}</h2>
            )}
        </>
    )
}
export default BOMOverview
