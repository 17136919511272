import { useTranslation } from 'react-i18next'
import Table from './Table'
import BreadCrumb from '~/components/BreadCrumb'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_TEAM_NEW,
} from '~/constants/Routes'
import PageName from '~/components/PageName'
import ListToolbar from '~/components/ListToolbar'
import { SUPER_ADMIN } from '~/constants/constants'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '~/store/auth/selector'
import { useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ComponentTooltip from '~/components/ComponentTooltip'

const permissions = [SUPER_ADMIN]

const Team = () => {
    const { t } = useTranslation()
    const userPermissions = useSelector(selectUserPermissions)
    const BreadComponents = [
        { route: ADMINISTRATOR, title: t(`breadCrumb.Administrator`) },
        {
            route: ADMINISTRATOR_DEFINITION,
            title: 'Definitions',
        },
        {
            route: ADMINISTRATOR_DEFINITION_TEAM,
            title: 'Teams',
        },
    ]

    const toolBarButtons = useMemo(() => {
        return [
            {
                button: 'create',
                navigateLink: ADMINISTRATOR_DEFINITION_TEAM_NEW,
                icon: <AddIcon />,
                isPermission: () => {
                    return permissions.some((item) =>
                        userPermissions.includes(item),
                    )
                },
            },
        ]
    }, [userPermissions])

    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_DEFINITION_TEAM}
            >
                <div>
                    <PageName name={t(`breadCrumb.Teams`)} />
                </div>
            </ComponentTooltip>
            <ListToolbar toolBarButton={toolBarButtons} />
            <Table />
        </>
    )
}

export default Team
